import Head from "next/head";
import { useRouter } from "next/router";
import Start from "@/components/Session/Start";
import { Program } from "@/models/program";
import Session from "@/components/Session";
import App from "@/components/App";
import getPathStaticProps from "@/db/static/getPathStaticProps";
import { ownerDomain, ownerFavicon, ownerTitle } from "@/lib/owner";
import { Owner } from "@/models/owner";
import getPathStaticPaths from "@/db/static/getPathStaticPaths";
import { Folder } from "@/models/folder";
import FolderComponent from "@/components/Folder";
import useUid from "@/hooks/useUid";

type Props = {
  owner: Owner;
  folder?: Folder;
  program?: Program;
};
const PathPage = ({ owner, folder, program }: Props) => {
  const router = useRouter();
  const sessionId = router.query.session as string;
  const uid = useUid();

  return (
    <>
      <App
        owner={owner}
        program={program}
        folder={folder}
        sessionId={sessionId}
        topBar={sessionId ? "session" : "app"}
        sideBar={sessionId ? undefined : "app"}
      >
        {!sessionId && program && <Start />}
        {!!sessionId && program && <Session />}
        {folder && <FolderComponent />}
      </App>
      {!!(program || folder) && !!owner && (
        <Head>
          <title>
            {program?.title || folder?.title} • {ownerTitle(owner)}
          </title>
          {!(program?.active || folder?.active) && (
            <meta name="robots" content="noindex" />
          )}
          <meta
            name="description"
            content={program?.description || folder?.description}
          />
          <meta name="image" content={program?.coverImage || undefined} />
          <meta
            name="url"
            content={`${ownerDomain(owner)}/${(
              program?.slugPaths || folder?.slugPaths
            ).join("/")}/${program?.slug || folder?.slug}`}
          />
          <meta
            name="og:title"
            content={`${program?.title || folder?.title} • ${ownerTitle(
              owner
            )}`}
          />
          <meta
            name="og:description"
            content={program?.description || folder?.description}
          />
          <meta name="og:image" content={program?.coverImage || undefined} />
          <meta
            name="og:url"
            content={`${ownerDomain(owner)}/${(
              program?.slugPaths || folder?.slugPaths
            ).join("/")}/${program?.slug || folder?.slug}`}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {!!ownerFavicon(owner) && (
            <link rel="icon" href={ownerFavicon(owner)} />
          )}
        </Head>
      )}
    </>
  );
};
export default PathPage;

export const getStaticPaths = getPathStaticPaths;
export const getStaticProps = getPathStaticProps;
